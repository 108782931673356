window._ = require('lodash');
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let cfr_token = document.querySelector('meta[name="csrf-token"]');
if (cfr_token !== null) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = cfr_token.getAttribute('content');
    window.csrf = cfr_token.getAttribute('content');
}

window.sweet = require('sweetalert2');
window.Vue = require('vue').default;
//Vue.component('sneg-catalog', require('./components/SnegCatalog.vue').default);
if (document.getElementById('app') !== null) {
    const app = new Vue({
        el: '#app'
    });
}

window.$ = window.jQuery = require("jquery/dist/jquery.slim.min");
window.fancybox = require("@fancyapps/fancybox/dist/jquery.fancybox.min");


import '@splidejs/splide/css';
import '@splidejs/splide/css/skyblue';
import '@splidejs/splide/css/sea-green';
import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';

let options = {
    type: 'loop',
    padding: 10,
    gap: 10,
    perPage: 3,
    arrows: false,
    pagination: false,
    focus: 'center',
    mediaQuery: 'max',
    breakpoints: {
        640: {
            perPage: 2,
            autoWidth: true
        }
    }
};
let elms = document.getElementsByClassName('splide');
for (var i = 0; i < elms.length; i++) {
    new Splide(elms[i], options).mount();
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function openPopup(id) {
    $.fancybox.open([{
        touch: false, src: '#' + id, titleShow: false, autoScale: true, openEffect: 'fade',
    }]);
    return false;
}

if (typeof sendForm !== "undefined") if (sendForm === true) openPopup('success_popup');

let formList = document.getElementsByTagName('form');
let dopInfo = document.getElementById('dop_info-tpl');
let geoInput = document.createElement('input');

geoInput.name = 'geo';
geoInput.type = 'hidden';

/*let promise = new Promise((resolve, reject) => {
    if (getCookie('geo_param').length === 0) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", "/information", true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let json = JSON.parse(xhr.responseText);
                setCookie('geo_param', JSON.stringify(json));
                geoInput.value = JSON.stringify(json);
                resolve(geoInput);
            }
        };
        xhr.send();
    } else {
        geoInput.value = getCookie('geo_param');
        resolve(geoInput);
    }
});*/
for (let i = 0; i < formList.length; i++) {
    formList[i].appendChild(dopInfo.cloneNode(true));
}

/*promise.then(
    result =>  {
        for (let i = 0; i < formList.length; i++) {
            /!*    formList[i].addEventListener('submit', function (e) {
                   let button =  formList[i].querySelector('button[type="submit"]');
                    button.setAttribute('disabled','disabled');
                    button.disabled = true;
                });*!/
            formList[i].appendChild(result.cloneNode(true));
        }
    }
)*/

document.querySelector('body').addEventListener('click', function (e) {
    if (e.target.classList.contains('open_order_tech')) openPopup('popup-tech-form');
})


$(document).ready(function () {

    /*    $("input[name='phone']")
            .on('input', function (e) {
                $(this).val($(this).val().replace(/[^0-9+()-]/g, ''));
            }).attr('maxlength', 18).attr('required', 'required').attr('minlength', 6);*/
    $("input[name='phone']")
        .on('input', function (e) {
            $(this).val($(this).val().replace(/[^0-9+()-]/g, ''));
        }).attr('maxlength', 18).attr('minlength', 6);

    /*$('body').on('submit', 'form', function () {
        let form = $(this);
        form.find('button[type="submit"]')
            //.attr('disabled','disabled')
            .html('Отправка...')
            .prop('disabled', true)
            .css('opacity', '0.7')
    });*/

    $('a[href*="#"]:not([href="#"]):not(.fancybox)').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                document.getElementById(this.hash.slice(1)).scrollIntoView({behavior: 'smooth', block: 'start'})
                return false;
            }
        }
    });
})
$(document).mouseleave(function () {
    if (getCookie('exitPopup').length === 0) {
        let waitModalElem = document.getElementById('waitModal');
        if (waitModalElem) {
            let waitModal = bootstrap.Modal.getOrCreateInstance(waitModalElem, {
                backdrop: 'static'
            });
            waitModal.show();
            waitModalElem.addEventListener('hidden.bs.modal', function (event) {
                setCookie('exitPopup', 1);
            })
        }
    }
});

const xhrFormsSend = document.querySelectorAll('[data-xhr="true"]');
if (xhrFormsSend.length) {
    xhrFormsSend.forEach((form) => {

        let $phone = form.querySelector('[name="phone"]');
        let $email = form.querySelector('[name="email"]');

        if ($phone)
            $phone.setAttribute('required', 'required');

        if (!$phone && $email)
            $email.setAttribute('required', 'required');


        form.addEventListener('submit', (e) => {
            e.preventDefault();

            //let formIsModal = form.hasAttribute('data-modal-form');
            let formIsModal = false;

            if ($phone && $email
                && ($email.value === "" || $email.value === null)
                && ($phone.value === "" || $phone.value === null)) {

                sweet.fire({
                    icon: 'info',
                    iconHtml: `<span class="--send-content-icon error"></span>`,
                    html: "Необходимо заполнить номер телефона, почта по желанию",
                    confirmButtonText: "Попробую снова",
                    showClass: {
                        popup: 'swal2-show --sw-custom-popup',
                        backdrop: 'swal2-backdrop-show',
                        icon: 'swal2-icon-show --sw-custom'
                    }
                    //footer: '<a href="">Why do I have this issue?</a>'
                })
                return false;
            }

            form.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');

            let formData = new FormData(form);
            axios.post("/system/send-form", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                form.querySelector('[type="submit"]').removeAttribute('disabled');

                if (formIsModal) {

                    let parentElem = form.closest('.modal-body-form');
                    let formContentBlock = parentElem.querySelector('.--form-content');
                    formContentBlock.classList.add('d-none');

                    let sendContentBlock = parentElem.querySelector('.--send-content');
                    sendContentBlock.classList.remove('d-none');

                    sendContentBlock.querySelector('.--send-content-message').innerText = res.data.message;

                    let statusIcon = sendContentBlock.querySelector('.--send-content-icon');
                    statusIcon.classList.remove('error', 'success');
                    statusIcon.classList.add(res.data.status);

                    let parentModal = form.closest('div.modal');
                    parentModal.addEventListener('hidden.bs.modal', function (event) {

                        formContentBlock.classList.remove('d-none');
                        formContentBlock.classList.add('remove');

                        sendContentBlock.classList.add('d-none');
                    })


                } else {

                    let buttonText = 'Отлично';
                    if (res.data.status === 'error')
                        buttonText = 'Попробую снова'

                    sweet.fire({
                        icon: 'info',
                        iconHtml: `<span class="--send-content-icon ${res.data.status}"></span>`,
                        html: res.data.message,
                        confirmButtonText: buttonText,
                        showClass: {
                            popup: 'swal2-show --sw-custom-popup',
                            backdrop: 'swal2-backdrop-show',
                            icon: 'swal2-icon-show --sw-custom'
                        }
                        //footer: '<a href="">Why do I have this issue?</a>'
                    })
                }
            })
                .catch((err) => {
                    alert('Возникла ошибки...')
                });
        })
    })
}

const servicesElems = document.querySelectorAll('[data-service]');
servicesElems.forEach((elem) => {
    let serviceModal = document.getElementById('dynamicModal');
    if (serviceModal) {
        let serviceModalInstance = bootstrap.Modal.getOrCreateInstance(serviceModal)
        elem.addEventListener('click', function () {
            let title = elem.getAttribute('data-service');
            serviceModal.querySelector('.modal-body-form-title').innerHTML = title;
            serviceModal.querySelector('[name="discount"]').value = title;
            serviceModalInstance.show();
        });
    }
});
